import React, { Component } from 'react';
import innsCaracts from './innsCaracts.json'
import innsDishes from './innsDishes.json'
import innsNames from './innsNames.json'
import innsCommodities from './innsCommodities.json'
import npcHistories from './npcHistories.json'
import npcObjectives from './npcObjectives.json'
import npcAppearances from './npcAppearances.json'
import npcPersonnalities from './npcPersonnalities.json'
import npcSecrets from './npcSecrets.json'
import npcRecompenses from './npcRecompenses.json'
import npcGroups from './npcGroups.json'
import './App.css';
import RollTable from "./RollTable";
import { Container } from 'reactstrap';
import {Row} from "reactstrap";
import {Col} from "reactstrap";

class App extends Component {
  render() {
    return (
      <Container className="App">
          <header>
            <h1>Pathroller - Pathfinder Data Generator</h1>
          </header>
          <h2>Les tavernes</h2>
          <Row>
              <Col md="6">
                  <RollTable data={innsCaracts}/>
              </Col>
              <Col md="6">
                   <RollTable data={innsDishes}/>
              </Col>
              <Col md="6">
                  <RollTable data={innsNames}/>
              </Col>
              <Col md="6">
                  <RollTable data={innsCommodities}/>
              </Col>
          </Row>
          <h2>Les PNJ</h2>
          <Row>
              <Col md="6">
                  <RollTable data={npcHistories}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcObjectives}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcAppearances}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcPersonnalities}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcSecrets}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcRecompenses}/>
              </Col>
              <Col md="6">
                  <RollTable data={npcGroups}/>
              </Col>
          </Row>
      </Container>
    );
  }
}

export default App;
