import React, { Component } from 'react';
import {Button} from "reactstrap";
import {Row} from "reactstrap";
import {Col} from "reactstrap";

class Roller extends Component {

    constructor(props) {
        super(props);
        this.state = {rolledNumbers: [], rolledValues: []};

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        function getRandomArbitrary(min, max) {
            return Math.round(Math.random() * (max - min) + parseFloat(min));
        }

        function constructRangeArray(start, end) {
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        }

        let rolledNumbers = [];
        let rolledValues = [];

        let rollNumbers;
        if(this.props.unique) {
            rollNumbers = this.props.columns.length - 1;
        } else {
            rollNumbers = 1;
        }
        for(let i = 0; i < rollNumbers; i++) {
            let randomIndex = getRandomArbitrary(this.props.min, this.props.max);
            this.props.values.forEach(function (element) {
                let range;
                if (element.range.match('-')) {
                    range = constructRangeArray(Number(element.range.split('-')[0]), Number(element.range.split('-')[1]));
                } else {
                    range = element.range;
                }

                if (range.indexOf(randomIndex) !== -1) {
                    rolledNumbers.push(randomIndex);
                    if(this.props.unique) {
                        rolledValues.push(element.values[i]);
                    } else {
                        rolledValues.push(element.values.join(' '));
                    }
                }
            }.bind(this));
        }


        this.setState({rolledNumbers: rolledNumbers, rolledValues: rolledValues});
    }

    render() {
        return (
            <Row>
                <Col>
                    <Button onClick={this.handleClick} size="md">Roll</Button>
                </Col>
                <Col>
                    <div className=""><strong>{this.state.rolledNumbers.join(', ')}</strong> {Array.prototype.join.call(this.state.rolledValues, " ")}</div>
                </Col>
            </Row>
        );
    }
}

export default Roller;
