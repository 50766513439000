import React, { Component } from 'react';

class RollTableBody extends Component {
    render() {
        return this.props.data.map(function (element, index) {
            let textValues = element.values.map(function (value, index) {
                return <td key={index}>{value}</td>
            });

            return <tr key={index}>
                <td>{element.range}</td>
                {textValues}
            </tr>
        });
    }
}

export default RollTableBody;
