import React, { Component } from 'react';

class RollTableHeader extends Component {
    render() {
        const listItems = this.props.columns.map((column, index) =>
            <th key={index}>{column}</th>
        );
        return (
            listItems
        );
    }
}

export default RollTableHeader;
