import React, { Component } from 'react';
import RollTableHeader from "./RollTableHeader";
import RollTableBody from "./RollTableBody";
import Roller from "./Roller";
import { Table } from 'reactstrap';
import { Button } from 'reactstrap';
import { Collapse } from 'reactstrap';
import {Row} from "reactstrap";
import {Col} from "reactstrap";
import './RollTable.css';

class RollTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
        };
    }

    render() {
        const { open } = this.state;
        return (
            <div className="RollTable border">
                <Row>
                    <Col>
                        <h3 className="h5">{this.props.data.name}</h3>
                    </Col>
                    <Col>
                        <Button
                            onClick={() => this.setState({ open: !open })}
                            aria-controls={this.props.data.name.toLowerCase().replace(' ', '-')}
                            aria-expanded={this.state.open}
                            size="sm"
                            className="float-right"
                        >
                            Afficher la table
                        </Button>
                    </Col>
                </Row>
                <Roller columns={this.props.data.headers} min={this.props.data.min} max={this.props.data.max} unique={this.props.data.unique} values={this.props.data.values}/>
                <Row>
                    <Col>
                        <Collapse isOpen={this.state.open}>
                            <Table responsive size="sm" id={this.props.data.name.toLowerCase().replace(' ', '-')}>
                                <thead>
                                    <tr>
                                        <RollTableHeader columns={this.props.data.headers}/>
                                    </tr>
                                </thead>
                                <tbody>
                                    <RollTableBody data={this.props.data.values}/>
                                </tbody>
                            </Table>
                        </Collapse>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default RollTable;
